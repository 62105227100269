



























































































import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class AccountErrors extends Vue {
  @Prop() error!: string;
}
