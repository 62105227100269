















































































































































import AuthModule from '@/store/modules/AuthModule';
import UserAccountModule from '@/store/modules/UserAccountModule';
import { Component, Vue } from 'vue-property-decorator';
import accounterrors from '@/pages/account/components/AccountErrors.vue';

@Component({
  components: {
    accounterrors,
  },
})
export default class ResetPasswordPage extends Vue {
  newPassword: string | null = null;
  confirmNewPassword: string | null = null;
  showValidateMessage = false;
  email = '';

  async updatePasswordWithCode() {
    if (!this.validateAll) {
      this.showValidateMessage = true;
    } else {
      this.showValidateMessage = false;
      await UserAccountModule.updatePasswordWithCode({
        code: this.$route.params.resetpasswordcode,
        newPassword: this.newPassword!,
      });
      if (UserAccountModule.updatePasswordWithCodeSucceeded) {
        AuthModule.loginWithoutRedirect({
          usernameoremail:
            UserAccountModule.updatePasswordWithCodeEmail,
          password: this.newPassword!,
        });
      }
    }
  }

  get updatePasswordWithCodeIsProcessing() {
    return UserAccountModule.updatePasswordWithCodeProcessing;
  }

  get updatePasswordWithCodeSucceeded() {
    return UserAccountModule.updatePasswordWithCodeSucceeded;
  }

  get updatePasswordWithCodeError() {
    return UserAccountModule.updatePasswordWithCodeError;
  }

  async created() {
    await UserAccountModule.verifyPasswordResetCode(
      this.$route.params.resetpasswordcode,
    );
  }

  get verifyPasswordResetCodeSucceeded() {
    return UserAccountModule.verifyPasswordResetCodeSucceeded;
  }

  get verifyPasswordResetCodeProcessing() {
    return UserAccountModule.verifyPasswordResetCodeProcessing;
  }

  get verifyPasswordResetCodeError() {
    return UserAccountModule.verifyPasswordResetCodeError;
  }

  get validateAll(): boolean {
    return (
      this.validatePassword === true &&
      this.validateConfirmPassword === true
    );
  }

  get validatePassword() {
    if (this.newPassword) {
      return this.newPassword!.length >= 8;
    } else if (this.showValidateMessage) {
      return false;
    } else {
      return null;
    }
  }

  get validateConfirmPassword() {
    if (this.confirmNewPassword) {
      return (
        this.confirmNewPassword!.length >= 8 &&
        this.newPassword === this.confirmNewPassword
      );
    } else if (this.showValidateMessage) {
      return false;
    } else {
      return null;
    }
  }
}
